import React, { Suspense, useLayoutEffect } from "react";
import {
  Home,
  Contact,
  About,
  Blogs,
  Blog,
  Services,
  NotFound,
  CreateBlog,
  Login,
} from "./routes";
import {Routes, Route , useLocation } from "react-router-dom";
import LoadingComponent from "./Shared/LoadingComponent";
import PrivateRoute from "./Auth/PrivateRoute";

function App() {

  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <Suspense fallback={<LoadingComponent />}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/blog" element={<Blogs />} />
          <Route path="/Login" element={<Login />} />
          <Route
            path="/createBlog"
            exact={true}
            element={
              <PrivateRoute>
                <CreateBlog />{" "}
              </PrivateRoute>
            }
          />
          <Route path="/blog/:blogId" element={<Blog />} />
          <Route path="/services" element={<Services />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </>
  );
}

export default App;
