export const landingImage = "https://res.cloudinary.com/dixvcadud/image/upload/q_auto/v1643739766/Teldev/image_s1kk1h.jpg"
export const aboutImage = "https://res.cloudinary.com/dixvcadud/image/upload/q_auto/v1643736261/Teldev/about_ktveyj.jpg"
export const olutundeImage = "https://res.cloudinary.com/dixvcadud/image/upload/q_auto/v1643959505/Teldev/olutunde_fgvwwn.jpg"
export const joshuaImage = "https://res.cloudinary.com/dixvcadud/image/upload/q_auto/v1643959503/Teldev/joshua_pb1bq3.jpg"
export const kayodeImage = "https://res.cloudinary.com/dixvcadud/image/upload/q_auto/v1643959503/Teldev/kayode_aeyyec.jpg"
export const companyLogo = "https://res.cloudinary.com/dixvcadud/image/upload/v1645115578/Teldev/main_logs_oe84ts.png"
export const webDevImage= "https://res.cloudinary.com/ojek12/image/upload/v1645458694/webdev_Teldev_wbnidb.png"
export const educationImage= "https://res.cloudinary.com/ojek12/image/upload/v1645458685/education_web_dev_dz1ocb.png"
export const itSolutionsImage="https://res.cloudinary.com/ojek12/image/upload/v1645458681/it_solutions_Teldev_aufghm.png"
export const companyName = "https://res.cloudinary.com/dixvcadud/image/upload/v1645112970/Teldev/teldev_full_logo_j03sod.png"
export const tedlevAcademyImage="https://res.cloudinary.com/ojek12/image/upload/v1645482150/TELDEV_ED_vivoqw.png"