import React from 'react';
import { companyLogo } from '../utils/images/images';

const LoadingComponent = ({ type }) => {
	return (
		<div className="flex items-center flex-col min-h-screen justify-center">
			{!type ? (
				<div className="animate-b">
					<img src={companyLogo} className="h-36 w-36" alt="Company logo" />
				</div>
			) : (
				<>
					{' '}
					<svg
						style={{ borderTopColor: 'transparent' }}
						className="animate-spin h-20 w-20 rounded-full border-blue-700 border-4 border-solid"
						viewBox="0 0 24 24"
					></svg>
					<span className="text-gray-500 text-2xl mt-4">Loading...</span>
				</>
			)}
		</div>
	);
};

export default LoadingComponent;
