import React, { createContext, useState } from 'react';
import jwt_decode from "jwt-decode";
import Axios from 'axios';
import Cookies from 'js-cookie'

const AuthContext = createContext()

export default AuthContext

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(() => Cookies.get('td_at') ? jwt_decode(Cookies.get('td_at')) : null)
    const [accessToken, setAccessToken] = useState(() => Cookies.get('td_at') ? Cookies.get('td_at') : null)

    const loginUser = async (body) => {
        const config = {
            headers: {
                "Content-type": "application/json",
            },
        };

        const { data, status } = await Axios.post(
            "https://teldev-website.herokuapp.com/api/auth/login",
            body,
            config
        );
        if (status === 200) {
            setUser(jwt_decode(data.access_token))
            setAccessToken(data.access_token)
            
            Cookies.set('td_at', data.access_token)
            return { data, status }
        } else {
            return { data, status }
        }

    }

    const logout = () => {
        setUser(null)
        setAccessToken(null)
        Cookies.remove('td_at')
    }


    const createBlog = async (body) => {
        const config = {
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${accessToken}`
            },
        };
        const { data, status } = await Axios.post(
            "https://teldev-website.herokuapp.com/api/posts/NewPost/", 
            body,
            config,

           
        );
        return { data, status }
    }
    let contextData = {
        user,
        loginUser,
        logout,
        createBlog
    }

    return (
        <AuthContext.Provider value={contextData}>
            {children}
        </AuthContext.Provider>
    )
}