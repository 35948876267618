import { lazy } from 'react';

export const Home = lazy(() => import('./Components/Home'))

export const About = lazy(() => import('./Components/About'))

export const Contact = lazy(() => import('./Shared/ContactModal'))

export const Blogs = lazy(() => import('./Components/Blogs'))

export const Blog = lazy(() => import('./Components/Blog'))

export const NotFound = lazy(() => import('./Components/NotFound'))

export const Services = lazy(() => import('./Components/Services'))

export const CreateBlog = lazy(() => import('./Auth/CreateBlog'))

export const Login = lazy(() => import('./Auth/Login'))